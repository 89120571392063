<template>
  <div>
    <el-dialog
      title="Apply to be a Supernode"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="60%"
      >
      <el-row :gutter="40">
        <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :rules="ruleInline"  ref="formLabelAlign">
          <el-col :span="12" :xs='24'>
            <el-form-item label="First Name" prop="firstName">
              <el-input v-model="formLabelAlign.firstName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs='24'>
            <el-form-item label="Last Name" prop="lastName">
              <el-input v-model="formLabelAlign.lastName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs='24'>
            <el-form-item label="Email" prop="email">
              <el-input v-model="formLabelAlign.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs='24'>
            <el-row>
              <el-form-item label="Phone" prop='phone'>
                <el-col :span="6">
                  <el-select v-model="phoneCode" filterable placeholder="Select">
                    <el-option
                      v-for="item in countryList"
                      :key="item.order"
                      :label="'+'+item.itc_code"
                      :value="item.itc_code">
                      <img :src="item.flag_path" alt class="option-img" />
                      <span>{{item.english}}</span>
                      <span>(+{{item.itc_code}})</span>
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="18">
                  <el-input v-model="formLabelAlign.phone"></el-input>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="12" :xs='24' >
            <el-form-item label="Company" prop='company'>
              <el-input v-model="formLabelAlign.company"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" :xs='24'>
            <el-form-item label="Title" prop='title'>
              <el-input v-model="formLabelAlign.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :xs='24'>
            <el-form-item label="Why are you interested?" prop='resion' class="mb0">
              <el-input type="textarea" v-model="formLabelAlign.resion"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="submit" @click="submitForm">Submit</el-button>
      </span>

      <el-dialog
        :visible.sync="robotModalflag"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="350px"
        :modal="false"
      >
        <div id="roxeSendEmail"></div>
        <p slot="footer"></p>
      </el-dialog>
    </el-dialog>
    <!-- <Supernodes></Supernodes> -->
  </div>
</template>

<script>
  import jsonList from "../components/country.json";
  import { onloadCallback } from "../../api/usersystem.js";
  import Axios from 'axios'

  export default {
    name: 'applySupernode',
    data() {
      const checkPhoneNumber = (rule, value, callback) => {
        const pattern = /[^\d]/;
        if (!value) {
          return callback(new Error("Required field"));
        } else if (value && pattern.test(value)) {
          return callback(new Error("Must be a number!"));
        } else if (value && (value.length < 8 || value.length > 15)) {
          return callback(new Error("should be 8-15 numbers"));
        } else {
          callback();
        }
      };
      return {
        mainTitle: "Resend Verification Email",
        maxWidth_600: true,
        robotModalflag:false,

        dialogVisible: false,
        labelPosition: 'top',
        formLabelAlign: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          company: '',
          title: '',
          resion: '',
        },
        countryList: [],
        phoneCode: '+1',
        ruleInline: {
        firstName: [
          {
            required: true,
            message: "Required field",
            trigger: "blur"
          }
        ],
        lastName: [
          {
            required: true,
            message: "Required field",
            trigger: "blur"
          }
        ],
        company: [
          {
            required: true,
            message: "Required field",
            trigger: "blur"
          }
        ],
        email: [
          { required: true, message: 'Required', trigger: 'blur' },
          { type: 'email', message: 'Please enter a correct email address', trigger: 'blur' }
        ],
        title: [
          {
            required: true,
            message: "Required field",
            trigger: "blur"
          }
        ],
        message: [
          {
            required: true,
            message: "Required field",
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            validator: checkPhoneNumber,
            trigger: "blur"
          }
        ],
      },
      }
    },
    mounted () {
      this.countryList = jsonList
    },
    methods: {
      handleClose() {
        this.$refs['formLabelAlign'].resetFields();
        this.dialogVisible = false
      },
      submitForm() {
        this.$refs['formLabelAlign'].validate((valid) => {
          // if (valid) {
            // // alert('submit!');
            // location=`mailto:service@roxe.tech?subject=Apply to be a Supernode&body=First Nam: ${this.formLabelAlign.firstName}; Last Name:  ${this.formLabelAlign.lastName}; 
            // Email: ${this.formLabelAlign.email}; Phone: +${this.phoneCode} ${this.formLabelAlign.phone}; Company: ${this.formLabelAlign.company}; Title: ${this.formLabelAlign.title}; resion: ${this.formLabelAlign.resion};`
          // } else {
          // }

          if (valid) {
          var _that = this;
          _that.robotModalflag = true;
          this.$nextTick(() => {
            onloadCallback(
              "roxeSendEmail",
              function(res) {
                console.log(res, 'res')
                if (res) {
                  // console.log(process.env.VUE_APP_TITLE + `/about-us/send-email`)
                  Axios.post(process.env.VUE_APP_TITLE + `/about-us/send-email`, {
                    formType: "ROXE_TECH_SUPER_NODE",
                    captchaType: "GOOGLE_WEB",
                    captchaCode: res,
                    data: {
                      firstName: _that.formLabelAlign.firstName,
                      lastName: _that.formLabelAlign.lastName,
                      CompanyName: _that.formLabelAlign.company,
                      email: _that.formLabelAlign.email,
                      phone: _that.phoneCode + _that.formLabelAlign.phone,
                      JobTitle: _that.formLabelAlign.title,
                      region: _that.formLabelAlign.resion,
                    }
                  })
                  .then(res => {
                    _that.robotModalflag = false;
                    _that.$message({
                      message: 'Success',
                      type: 'success'
                    });
                    _that.$refs['formLabelAlign'].resetFields();
                    _that.dialogVisible = false
                  })
                  .catch(err => {
                    console.log(err, 1)
                    _that.$message({
                      message: err.message || 'Fail',
                      type: 'error'
                    });
                    _that.robotModalflag = false;
                    _that.dialogVisible = false
                  })
                }
              });
          })
        } else {
          _that.$message({
            message: err.message || 'Fail',
            type: 'error'
          });
           _that.dialogVisible = false
          return false;
        }
        });
      }
    }
  }
</script>

<style lang="less" scoped>
/deep/ .el-dialog__title {
  color: #085DA3;
}
/deep/ .el-dialog__header{
  padding-top: 30px;
}
/deep/ .el-dialog__close {
  font-size: 24px;
  font-weight: 700;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
  margin: 0;
}
/deep/ .el-input__inner {
  border-radius: 0px;
  height: 46px;
  line-height: 46px;
}
/deep/ .el-dialog__footer {
  padding: 30px;
  padding-top: 0px;
}
/deep/ .el-dialog__body {
  padding: 30px;
}
/deep/ .el-input__inner, /deep/ textarea {
  background: #FAFCFE;
}
.submit {
  border-radius: 0px;
  background: #2F98EE;
}

</style>
<style lang="less">
.el-select-dropdown__item {
  height: 40px;
}
.el-scrollbar {
  width: 330px;
  img {
    border-radius: 40px;
    display:inline-block;
    height:30px;
    width: 30px;
    margin-right: 20px;
  }
  span {
    margin-right: 20px;
  }
}
.el-dialog__footer {
  text-align: left!important;
}
.mb0 {
  margin-bottom: 0px;
}
</style>